<template>
  <div class="main-content main-content--auth" v-show="googleFinished">
    <div class="auth-container">
      <router-link class="btn btn--primary btn--link" to="Home"><img class="icon" src="@/assets/images/icons/ico_chev_left--orange.svg"> Volver al inicio</router-link>
      <div class="auth">
        <img class="logo" src="@/assets/images/logo_dorapp--or.svg" alt="En la imagen el logo de Dorapp">
        <template v-if="googleWorked">
        <div class="auth-titles">
            <h3>Inicia sesión</h3>
            <p>Ingresa a tu cuenta con alguna de tus redes sociales</p>
          </div>
          <div id="google" class="social-auth">
            <!--<button id="btncustom" class="social-auth__btn btn btn--primary btn--block btn--google" @click="google">Google</button>-->
            <div id="GoogleButton" class="bnt--google"></div> 
          </div>
        </template>
        <template v-else>
          <div class="auth-titles">
            <h3>Error de Google</h3>
            <p>Google no cargó, asegúrate de no estar usando bloqueadores de rastreadores</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
	import { inject } from 'vue'
	export default {
		name: 'Login',
		setup() {
			const axios = inject('$axios')
			return { axios }
		},
		data() {
			return {
				email: '',
				password: '',
				show: false,
        submitting: false,
        authGoogle: false,
        googleWorked: true,
        googleFinished: false
			}
		},
    mounted(){
        //eslint-disable-next-line
      try{
        //eslint-disable-next-line
        google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_API_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_API_RETURN_URI,
        callback: this.handleCredentialResponse
        });
        this.googleWorked = true
        //eslint-disable-next-line
        google.accounts.id.renderButton(
          document.getElementById("GoogleButton"),
          { theme: "outline", size: "large" }  // atributos customizables
          );
      }
      catch{
        this.googleWorked = false
      }
      this.googleFinished = true
        //eslint-disable-next-line
        //google.accounts.id.prompt(); // one-tap
    },
		methods: {
      async handleCredentialResponse(response){
        this.submitting = true
        try{
          if(response != null){
            this.authGoogle = true
            const res = await this.$axios.post('Users/googleAuthenticate',
            {
              token: response.credential,
              AuthMethod: 1
            });
            if (res.data.token) {
              const token = res.data.token
              let authToken = `bearer ${token}`
              this.axios.defaults.headers.common['Authorization'] = authToken
              this.axios.get('init').then(() => {
                this.$cookies.set('jwtA', authToken);
                this.$router.push('/home')
              })
            } else {
              this.$toast.error(`El correo utilizado no se encuentra registrado en la plataforma`)
              this.submitting = false;
            }
          }else{
            this.authGoogle = false
            this.$toast.error(`Ocurrio un problema con Google`)
          }
        }catch(ex){
          this.$toast.error(`Ocurrió un error`)
          this.submitting = false;
        }
        
      },
			/*async signin() {
        this.submitting = true;
        try{
          const res = await this.$axios.post('Users/authenticate',
          {
            email: this.email,
            password: this.password
          });
          if (res.data.token) {
            const token = res.data.token //TODO: reemplazar por API hdc
            let authToken = `bearer ${token}`
            this.axios.defaults.headers.common['Authorization'] = authToken
            this.axios.get('init').then(() => {
              this.$cookies.set('jwtA', authToken)
              this.$router.push('/home')
            })
          } else {
            this.$toast.error(`El correo electrónico o la contraseña son incorrectos`)
            this.submitting = false;
          }
        }catch(ex){
          this.$toast.error(`Ocurrió un error`)
          this.submitting = false;
        }  
			}*/
		},
		beforeMount() {
			if (this.$cookies.isKey('jwtA')) {
				this.$cookies.remove('jwtA')
				this.$router.push('/home')
			}
			else
				this.show = true
		}, 
	}
</script>
